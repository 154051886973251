import * as Consts from "gatsby-theme-fela/src/constants"

import styles, { theme } from "gatsby-theme-fela/src/styles"
import defaultFonts from "gatsby-theme-fela/src/fonts"

import plugins from "gatsby-theme-fela/src/config/plugins"
import enhancers from "gatsby-theme-fela/src/config/enhancers"

export const rendererConfig = {
  devMode: Consts.isEnvDevelopment,
  enhancers,
  plugins,
  theme,
  mediaQueryOrder: [
    '(max-width: 599px)',
    '(min-width: 600px)',
    '(min-width: 900px)',
    '(min-width: 1199px)',
    '(min-width: 1200px)',
    '(min-width: 320px) and (max-width: 1200px)',
  ]
}

export const staticCSS = styles

export const fonts = defaultFonts
